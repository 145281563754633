
#smiley-rocks-default-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#smiley-rocks-default-container img {
  width: calc(100% - 50px);
  height: auto;
  padding-bottom: 200px;

  @media only screen and (min-width: 500px) {
    width: auto;
    height: calc(100%/1.5);
  }
}
