@import './colors';

.text-header-alt {
  font-family: 'Amatic SC', sans-serif;
}

.text-smiley-rocks-pink {
  color: $smiley-rocks-pink;
}

.text-smiley-rocks-yellow {
  color: $smiley-rocks-yellow;
}

.text-smiley-rocks-yellow-alt {
  color: $smiley-rocks-yellow-alt;
}

.text-smiley-rocks-black {
  color: $smiley-rocks-black;
}