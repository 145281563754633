::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-style: italic;
  opacity: 0.8;
}

::-moz-placeholder { /* Firefox 19+ */
  font-style: italic;
  opacity: 0.8;
}

:-ms-input-placeholder { /* IE 10+ */
  font-style: italic;
  opacity: 0.6;
}

:-moz-placeholder { /* Firefox 18- */
  font-style: italic;
  opacity: 0.8;
}

input[type="checkbox"] {
  height: 14px;
  width: 14px;
  left: 7px;
}

input[type="checkbox"] {
  transform: scale(1.5);
  margin: 0 5px 0 7px;
}
input[type="checkbox"].form-control-lg {
  transform: scale(2);
}
