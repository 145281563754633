
$bg-image: url('../../images/smiley-rocks-share-rock-blank.png');

#smiley-rocks-share-info li {
  padding: 10px;
}
#smiley-rocks-share-info .smiley-rock-share {
  background-image: $bg-image;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;


  width: 100%;
  max-width: 500px;
  max-height: 400px;
  height: calc((100vw - 50px) * 0.8);
}

#smiley-rocks-share-info .letter-spacing {
  margin: 0 0.15em;
}

#smiley-rocks-share-info .label {
  font-weight: 100;
}

#smiley-rocks-share-info .data {
  font-weight: 600;
  font-size: 1.1rem;
}

#smiley-rocks-share-info .smiley-rock-share-text {
  font-family: 'Architects Daughter', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: 3em;
  transform: rotate(18deg);

  @media only screen and (min-width: 500px) {
    font-size: 5em;
  }
}
