@import './colors';

.btn-smiley-primary {
  color: #fff;
  background-color: rgba($smiley-rocks-pink, 0.9);
  border-color: $smiley-rocks-pink;
}

.btn-smiley-primary:focus, .btn-smiley-primary:hover {
  color: #fff !important;
  background-color: $smiley-rocks-pink;
  border-color: rgba($smiley-rocks-yellow, 0.5);
}

.btn-smiley-primary:disabled {
  color: #fff;
}