@import '../../styles/colors';

header {
  width: 100%;
  position: sticky;
  // border-bottom: 1px solid rgba(0,0,0, 0.5);
  // box-shadow: 0px 0px 17px 0px rgba(255,255,255,0.75);
}

header .navbar-collapse.show,
header .navbar-collapse.collapsing {
  background: rgba(255, 255, 255, 0.05);
  padding: 0px 20px
}

// header .navbar-collapse.show .active, header .navbar-collapse.collapsing .active {

// }

header .nav-link {
  color: $smiley-rocks-yellow !important;
}

header .nav-link.disabled {
  color: rgba($smiley-rocks-grey, 0.45) !important;
  cursor: not-allowed !important;
}

header .nav-link.active {
  border-bottom: 1px solid rgba($smiley-rocks-yellow, 0.25) !important;
  font-weight: 900 !important;
}

header h1 {

  @media only screen and (min-width: 700px) {
    font-size: 40px;
  }
}