$enable-responsive-font-sizes: true;
@import "~bootstrap/scss/bootstrap";

@import url('https://fonts.googleapis.com/css2?family=Merriweather&family=Montserrat&family=Amatic+SC&family=Indie+Flower&family=Shadows+Into+Light&family=Architects+Daughter');
@import './animations';
@import './colors';
@import './text';
@import './input';
@import './sky-gradients';
@import './buttons';
$bg-image: url('../images/grass-footer.png');

body {
  margin: 0;
  padding: 0;

  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #fff;
  background: $sky-gradient-06;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Amatic SC', Merriweather, sans-serif;
}

#smiley-rocks-app-container {
  height: 100vh;
  width: 100vw;

  display: flex;
  flex-direction: column;

  overflow: hidden;

  // background-attachment: fixed;
  // background-image: $bg-image;
  // background-repeat: repeat-x;
  // background-position: bottom;
}

#smiley-rocks-main-container {
  flex-grow: 2;

  display: flex;
  justify-content: space-around;

  overflow: hidden;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
}

// .container {
//   width: 100%;
//   height: 100%;
//   overflow: hidden;
// }

.overflow-scroll {
  overflow: scroll;
}

.box-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}