footer {
  height: 20px;
  width: 100%;

  background-color: #000;
  color: #fff;

  display: flex !important;
  justify-content: center;
  align-items: center;
  font-weight: 100;
  font-size: .8rem;
}